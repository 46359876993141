@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

@layer components {
  .btn-resizable {
    @apply btn-xs transition-all sm:btn-sm md:btn-md lg:btn-lg;
  }
}

@layer utilities {
  .status-queue {
    @apply bg-gradient-to-r from-neutral to-gray-500;
  }
  .status-scheduled {
    @apply bg-gradient-to-r from-neutral to-info;
  }
  .status-executing {
    @apply bg-gradient-to-r from-teal-300 to-green-300;
  }
  .status-error {
    @apply bg-gradient-to-r from-pink-500 to-error;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .animate-wobble-scale {
    animation: wobble-scale 1500ms infinite;
    transform-origin: center;
  }
  .modern-gradient {
    background-image: linear-gradient(
      360deg in oklch,
      rgb(10% 6% 23%) 0%,
      oklch(80% 0.3 225) 100%
    );
  }
  .custom-drop-shadow-light {
    --tw-drop-shadow: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .custom-drop-shadow-heavy {
    --tw-drop-shadow: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.25));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .moving-border {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 10px;
    overflow: hidden;

    &::before {
      @apply bg-accent;
      content: "";
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 100%;
      /* background-color: #1a232a; */
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: conic-gradient(
        transparent,
        rgba(168, 239, 255, 1),
        transparent 30%
      );
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 6px;
      top: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background: #000;
      border-radius: 3px;
    }
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}
/* 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
} */

@keyframes wobble-scale {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.1);
  }
  25% {
    transform: scale(0.9);
  }
  35% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1.02);
  }
  85% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
